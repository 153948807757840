import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "./Library.css";

const Library = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_HOST +
        `/api/public/list/b41afa3b-b52f-439a-ac96-f3102120006d?filter={"field":"live","value":"yes"}`
    )
      .then((response) => response.json())
      .then((data) => {
        setBooks(data.items);
      })
      .catch((error) => console.error("Error fetching books:", error));
  }, []);

  return (
    <div className="library">
      <h1>Audiobook Library</h1>
      <div className="bookshelf">
        {books.map((book) => (
          <Link key={book.id} to={`/book/${book.id}`}>
            <div className="book">
              <img src={book.cover} alt={book.title} className="book-cover" />
              <h3 className="book-title">{book.title}</h3>
              <p className="book-author">by {book.author}</p>
              <p className="book-summary">{book.summary}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Library;
