import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Library from "./pages/Library";
import BookPage from "./pages/BookPage";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Library />} />
          <Route path="/book/:bookId" element={<BookPage />} />
          <Route path="/book/:bookId/:chapterId" element={<BookPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
