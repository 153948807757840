import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import AudioPlayer from "../components/AudioPlayer";
import "./BookPage.css";
import {
  FaArrowLeft,
  FaArrowRight,
  FaHome,
  FaList,
  FaMusic,
  FaPlayCircle,
} from "react-icons/fa";

const BookPage = () => {
  const { bookId, chapterId } = useParams();
  const [book, setBook] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [currentChapter, setCurrentChapter] = useState(null);
  const [showChapterList, setShowChapterList] = useState(false);
  const [chapterContent, setChapterContent] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAudioPlayer, setShowAudioPlayer] = useState(true);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioProgress, setAudioProgress] = useState(0); // Store audio progress
  const navigate = useNavigate();

  const LOCAL_STORAGE_KEY = `book_${bookId}_progress`;

  useEffect(() => {
    // Fetch the book details
    fetch(process.env.REACT_APP_HOST + `/api/public/item/${bookId}`)
      .then((response) => response.json())
      .then((data) => {
        setBook(data.item);
      })
      .catch((error) => console.error("Error fetching book details:", error));

    // Fetch the chapters
    fetch(
      process.env.REACT_APP_HOST +
        `/api/public/list/731db7b3-1dfa-403f-b868-e3a33d62d20d?filter={"field":"book","value":"${bookId}"}`
    )
      .then((response) => response.json())
      .then((data) => {
        const sortedChapters = data.items.sort((a, b) => a.number - b.number);
        setChapters(sortedChapters);

        // Check for saved progress in localStorage
        if (!chapterId) {
          fetchChapterContent(sortedChapters[0].id, 0);
        }

        const chapterIndex = sortedChapters.findIndex(
          (chapter) => chapter.id === chapterId
        );
        if (chapterIndex !== -1) {
          fetchChapterContent(chapterId, chapterIndex);
        }
      })
      .catch((error) => console.error("Error fetching chapters:", error));
  }, [bookId, chapterId]);

  const fetchChapterContent = (chapterId, index, savedAudioProgress = 0) => {
    fetch(process.env.REACT_APP_HOST + `/api/public/item/${chapterId}`)
      .then((response) => response.json())
      .then((data) => {
        setCurrentChapter(data.item);
        setCurrentIndex(index);
        setAudioProgress(savedAudioProgress); // Set audio progress from saved data

        // Fetch the markdown file content from the URL
        if (data.item.text.includes("http")) {
          fetch(data.item.text)
            .then((response) => response.text())
            .then((text) => {
              setChapterContent(text);
            })
            .catch((error) =>
              console.error("Error fetching chapter content:", error)
            );
        } else {
          setChapterContent(data.item.text);
        }

        // Check if there's an audio field and set the audio URL
        if (data.item.audio) {
          setAudioUrl(data.item.audio);
        } else {
          setAudioUrl(null);
        }

        // Save progress to localStorage
        localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify({ chapterId, audioProgress: savedAudioProgress })
        );
      })
      .catch((error) =>
        console.error("Error fetching chapter content:", error)
      );
  };

  const handleChapterClick = (chapter, index) => {
    navigate(`/book/${bookId}/${chapter.id}`);
    fetchChapterContent(chapter.id, index);
    setShowChapterList(false);
  };

  const handleNextChapter = () => {
    let i = 0;
    for (i = 0; i < chapters.length; i++) {
      if (chapters[i].id === currentChapter.id) {
        const nextChapter = chapters[i + 1];
        console.log("Found current chapter at index", nextChapter);
        if (nextChapter) {
          window.location.href = `/book/${bookId}/${nextChapter.id}`;
          break;
        }
        break;
      }
    }

    // navigate(`/book/${bookId}/${nextChapter.id}`);
    // window.location.href = `/book/${bookId}/${nextChapter.id}`;
    // fetchChapterContent(nextChapter.id, currentIndex + 1);
  };

  const handleAudioProgressUpdate = (progress) => {
    setAudioProgress(progress);
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        chapterId: currentChapter.id,
        audioProgress:
          Math.round(progress) - 10 > 0 ? Math.round(progress) - 10 : 0,
      })
    );
  };

  return (
    <div className="book-page">
      {currentChapter && (
        <div className="chapter-content">
          <h2>Chapter {currentChapter.number}</h2>
          <h3>{currentChapter.title}</h3>
          <div
            style={{
              maxWidth: "800px",
              margin: "0 auto",
              fontSize: "1.2em",
              fontFamily: "Merriweather, serif",
              textAlign: "justify",
              paddingBottom: "200px",
            }}
          >
            <ReactMarkdown>{chapterContent}</ReactMarkdown>
          </div>
        </div>
      )}
      {showAudioPlayer &&
        currentChapter &&
        currentChapter.audio &&
        audioUrl && (
          <AudioPlayer
            audioUrl={audioUrl}
            chapterTitle={currentChapter?.title}
            chapterNumber={currentChapter?.number}
            showAudioPlayer={showAudioPlayer}
            toggleAudioPlayer={() => setShowAudioPlayer(!showAudioPlayer)}
            initialProgress={audioProgress} // Pass the saved progress
            onAudioProgressChange={handleAudioProgressUpdate} // Update progress
            onAudioEnd={() => {
              handleNextChapter();
            }} // Go to next chapter when audio ends
            bookId={bookId}
          />
        )}
      <div>
        {/* Menu Bar */}
        <div className="menu-bar" style={styles.menuBar}>
          {/* Back to Library Icon */}
          <button onClick={() => navigate("/")}>
            <FaHome title="Back to Library" />
          </button>

          {/* Show/Hide Chapter List Icon */}
          <button onClick={() => setShowChapterList(!showChapterList)}>
            {showChapterList ? (
              <FaList title="Hide Chapters" />
            ) : (
              <FaList title="Show Chapters" />
            )}
          </button>
          {/* Show/Hide Audio Player Icon */}
          <button onClick={() => setShowAudioPlayer(!showAudioPlayer)}>
            {showAudioPlayer ? (
              <FaPlayCircle title="Hide Player" />
            ) : (
              <FaPlayCircle title="Show Player" />
            )}
          </button>

          {/* Next Chapter Icon */}
          <button
            onClick={handleNextChapter}
            disabled={chapters && currentIndex >= chapters.length - 1}
          >
            <FaArrowRight title="Next Chapter" />
          </button>
        </div>
      </div>

      {/* Display the chapter list when the button is clicked */}
      {showChapterList && (
        <div className="chapter-list">
          {chapters.map((chapter, index) => (
            <div
              key={chapter.id}
              className="chapter-item"
              onClick={() => {
                window.location.href = `/book/${bookId}/${chapter.id}`;
              }}
            >
              Chapter {chapter.number}: {chapter.title}
            </div>
          ))}
          <div
            style={{
              height: "200px",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

// Styles for the menu bar
const styles = {
  menuBar: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f7f7f7",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
};

export default BookPage;
