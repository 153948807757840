import React, { useEffect, useState, useRef } from "react";
import ReactHowler from "react-howler";
import {
  FaPlay,
  FaPause,
  FaForward,
  FaBackward,
  FaVolumeUp,
} from "react-icons/fa";

const AudioPlayer = ({
  audioUrl,
  chapterTitle,
  chapterNumber,
  audioProgress,
  onAudioProgressChange,
  onAudioEnd,
  bookId,
}) => {
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1.0);
  const [duration, setDuration] = useState(0);
  const [seek, setSeek] = useState(audioProgress || 0);
  const [playbackRate, setPlaybackRate] = useState(1.0); // Add state for playback speed
  const playerRef = useRef(null);

  // Handle audio load success
  const handleLoad = () => {
    if (playerRef.current) {
      setLoading(false);
      const audioDuration = playerRef.current.duration();
      setDuration(audioDuration);
      if (localStorage.getItem("book_" + bookId + "_progress")) {
        const progress = JSON.parse(
          localStorage.getItem("book_" + bookId + "_progress")
        );
        setSeek(progress.audioProgress);
        setIsPlaying(progress.isPlaying);
        // playerRef.current.seek(progress.audioProgress);
      }
    }
  };

  // Handle play/pause toggle
  const togglePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  // Handle volume change
  const handleVolumeChange = (event) => {
    setVolume(parseFloat(event.target.value));
  };

  // Handle playback rate change
  const handleSpeedChange = (event) => {
    const newRate = parseFloat(event.target.value);
    setPlaybackRate(newRate);
    if (playerRef.current) {
      playerRef.current._howler.rate(newRate); // Update playback rate
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Handle seek change
  const handleSeekChange = (event) => {
    const newSeek = parseFloat(event.target.value);
    setSeek(newSeek);
    playerRef.current.seek(newSeek);
    onAudioProgressChange(newSeek); // Update progress in parent
  };

  // Skip forward/backward functions
  const skipForward = () => {
    const newSeek = Math.min(seek + 30, duration);
    setSeek(newSeek);
    playerRef.current.seek(newSeek);
    onAudioProgressChange(newSeek);
  };

  const skipBackward = () => {
    const newSeek = Math.max(seek - 15, 0);
    setSeek(newSeek);
    playerRef.current.seek(newSeek);
    onAudioProgressChange(newSeek);
  };

  // Update seek position every second
  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current && isPlaying) {
        const currentSeek = playerRef.current.seek();
        setSeek(currentSeek);
        onAudioProgressChange(currentSeek);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isPlaying, onAudioProgressChange]);

  // Ensure the Howler instance is available before playing
  useEffect(() => {
    if (playerRef.current) {
      setIsPlaying(false); // Pause audio on URL change until manually played
    }
  }, [audioUrl]);

  return (
    <div className="audio-player" style={styles.audioPlayer}>
      {loading && <p>Loading audio...</p>}

      {audioUrl && (
        <ReactHowler
          src={audioUrl}
          playing={isPlaying}
          onLoad={handleLoad}
          onLoadError={() => setLoading(false)}
          onEnd={() => onAudioEnd()}
          volume={volume}
          loop={false}
          ref={playerRef}
          onPlay={() => setIsPlaying(true)}
          rate={playbackRate} // Set playback rate
        />
      )}
      <div style={styles.controlsContainer}>
        <button onClick={skipBackward} style={styles.controlButton}>
          <FaBackward size={24} />
        </button>

        <button onClick={togglePlayPause} style={styles.playPauseButton}>
          {isPlaying ? <FaPause size={36} /> : <FaPlay size={36} />}
        </button>

        <button onClick={skipForward} style={styles.controlButton}>
          <FaForward size={24} />
        </button>
      </div>

      <div style={styles.progressContainer}>
        {/* Display seek time in mm:ss */}
        <div
          style={{
            width: "50px",
            textAlign: "right",
          }}
        >
          {formatTime(seek)}
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <input
            type="range"
            min="0"
            max={duration}
            step="0.1"
            value={seek}
            onChange={handleSeekChange}
            style={styles.seekBar}
          />
        </div>
        {/* Display duration in mm:ss */}
        <div
          style={{
            width: "50px",
            textAlign: "left",
            paddingLeft: "18px",
          }}
        >
          {formatTime(duration)}
        </div>
      </div>

      {/* Playback Speed Control */}
      {/* <div style={styles.speedControlContainer}>
        <label htmlFor="speedControl">Speed: </label>
        <select
          id="speedControl"
          value={playbackRate}
          onChange={handleSpeedChange}
          style={styles.speedControl}
        >
          <option value="0.5">0.5x</option>
          <option value="1">1x</option>
          <option value="1.5">1.5x</option>
          <option value="2">2x</option>
        </select>
      </div> */}

      <p style={styles.audioInfo}>
        Now playing: {chapterTitle} - Chapter {chapterNumber}
      </p>
    </div>
  );
};

const styles = {
  audioPlayer: {
    position: "fixed",
    bottom: "50px",
    width: "calc(100vw - 40px)",
    background: "#f7f7f7",
    padding: "20px",
    textAlign: "center",
    overflow: "hidden",
    left: 0,
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
  playPauseButton: {
    background: "transparent",
    border: "none",
  },
  controlButton: {
    background: "transparent",
    border: "none",
  },
  progressContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto 10px",
  },
  seekBar: {
    width: "100%",
    margin: "0 10px",
  },
  speedControlContainer: {
    margin: "10px 0",
  },
  speedControl: {
    padding: "5px",
    fontSize: "14px",
  },
  audioInfo: {
    marginTop: "10px",
    fontSize: "14px",
  },
};

export default AudioPlayer;
